<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <!-- <div
          class="menu-link"
          v-if="getPermissionInterface('Suivi des paiement Obligé')"
        >
          <router-link class="menu-item" to="/obligee/suivi-paiement-obligee">
            Suivi des paiement Obligé
          </router-link>
        </div> -->
        <div
          class="menu-link"
          v-if="getPermissionInterface('Gestions des appels à paiement Obligé')"
        >
          <router-link
            class="menu-item"
            to="/obligee/gestion-appels-paiement-obligee"
          >
            Gestions des appels à paiement
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'getPermissionInterface'])
  }
};
</script>

<style scoped lang="scss"></style>
